<template>
  <div class="flex flex-col lg:flex-row lg:space-x-4">
    
      <vs-card class="flex-1 px-1" id="testID">
        
        <vs-button 
          color="dark" 
          type="flat"
          icon="arrow_back_ios"
          @click="goToInsights"
          class="p-0 lg:py-1 text-sm">
          Back to Insights
        </vs-button>

        <div class="mt-8 lg:mt-6">
          <p class="title text-lg font-bold mb-2"> Patient Trends </p>
          <div class="flex flex-col space-y-2">
            <p
              class="text-base py-2 px-4 cursor-pointer"
              :class="sideMenuOptions.age ? 'categoryActive' : 'bg-gray-100 rounded-lg'"
              @click="showBrand('age')"
            >
              Age Bracket
            </p>
            <p
              class="text-base py-2 px-4 cursor-pointer"
              :class="sideMenuOptions.gender ? 'categoryActive' : 'bg-gray-100 rounded-lg'"
              @click="showBrand('gender')"
            >
              Gender
            </p>
            <p
              class="text-base py-2 px-4 cursor-pointer"
              :class="sideMenuOptions.location ? 'categoryActive' : 'bg-gray-100 rounded-lg'"
              @click="showBrand('location')"
            >
              Location
            </p>
          </div>
        </div>
      </vs-card>

      <vs-card class="w-full lg:w-9/12">

        <div slot="header">
          <h5 class="text-green-dark font-bold my-3 mx-2">
            {{ tabHeader }}
          </h5>

          <vs-row
            vs-type="flex"
            vs-align="space-around"
            vs-w="12"
            v-if="!isFetching"
          >
            <vs-tabs v-model="active" class="insightsTabs">
              <vs-tab
                v-for="(tab, i) in tabsName"
                :key="`tab-${i}`"
                :label="tab.tab"
              >
                <Details
                  :key="`chart-${i}`"
                  :type="selectedTab"
                  :chartDataProps="chartData"
                  :selected="selected"
                  @updateChartData="chartData = $event"
                  :isAllClinicsProps="isAllClinics"
                  @updateIsAllClinics="isAllClinics = $event"
                  :urlType="urlType"
                  @updateFilters="selected = $event"
                ></Details>
              </vs-tab>
            </vs-tabs>
          </vs-row>
        </div>

        <vs-table
          id="clinicInsightsTable"
          v-if="filteredData && filteredData.length"
          max-items="10"
          pagination
          :data="filteredData"
          noDataText="No Data Available"
          :hoverFlat="true"
          :key="'main-' + tableKey"
          class="customtable w-full"
        >

          <template slot="thead">
            <vs-th v-for="(th, indexth) in customHeaders" :key="`ch-${indexth}`">
              <span v-if="indexth === 0" class="capitalize">{{
                selectedTab
              }}</span>
              <span v-else>{{ th }}</span>
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="`cr-${indextr}`" v-for="(tr, indextr) in data">
              <vs-td
                data-label="{ th }"
                v-for="(row, ii) in customHeaders"
                :key="`${tr[row]}-${ii}`"
                :data="tr[row]"
              >
                {{ tr[row] }}
              </vs-td>
            </vs-tr>
          </template>

        </vs-table>

     </vs-card>

  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import Sidebar from "./Sidebar.vue";
import Details from "../../components/insights/patient-trends/Details.vue";
import utils from "@/assets/utils";
import helper from "./helper";

export default {
  components: {
    Details,
    Sidebar,
  },
  data() {
    return {
      active: 0,
      chartData: {},
      selectedTab: "month",
      selectedType: "age",
      sideMenuOptions: {
        age: true,
        gender: false,
        location: false,
      },
      selected: {},
      filteredData: [],
      ageRows: [
        "Category",
        "Below 18",
        "18 - 29",
        "30 - 39",
        "40 - 49",
        "50 - 59",
        "60 - 69",
        "70 - 79",
        "80+",
        "Total",
      ],
      genderRows: ["Category", "Male", "Female", "Total"],
      customHeaders: [],
      urlType: "",
      defaultTabs: [
        {
          type: "month",
          tab: "By Month",
        },
        {
          type: "week",
          tab: "By Week",
        },
        {
          type: "year",
          tab: "By Year",
        },
        {
          type: "day",
          tab: "By Day",
        },
      ],
      locationTabs: [
        {
          type: "state",
          tab: "State",
        },
        {
          type: "postcode",
          tab: "Postcode",
        },
        {
          type: "suburb",
          tab: "Suburb",
        },
      ],
      tabsName: [],
      isFetching: false,
      isAllClinics: false,
      orgId: "",
      tableKey: "",
    };
  },
  computed: {
    ...mapState("analytics", ["FILTERS_SELECTED"]),
    tabHeader() {
      let header = "Age Bracket";
      if (this.selectedType === "gender") header = "Gender";
      if (this.selectedType === "location") header = "Location";
      return header;
    },
  },
  methods: {
    ...mapActions("analytics", ["fetchPatientTrend", "fetchAnalyticsFilters"]),
    goToInsights() {
      this.$router.push({ name: `${this.urlType}Insights` });
    },
    getPatientTrends() {
      this.$vs.loading();
      let payload = {
        type: this.selectedType,
        params: helper.generateAnalyticsAPIParameters(this.selected),
      };

      if (!["SuperAdmin", "Supplier"].includes(this.urlType)) {
        payload.params.org = [this.orgId];
      }

      payload.params.type = this.selectedTab;

      if (this.urlType === "Supplier" && !this.isAllClinics) {
        if (this.selected.brand.length) {
          payload.params.brand = this.selected.brand;
        } else {
          payload.params.brand = this.supplierCurrentBrands;
        }
      }
      if (this.selectedTab === "year" && this.selectedType === "gender") {
        payload.params.type = "years";
      }
      console.log(payload, this.urlType, this.isAllClinics, this.supplierCurrentBrands)
      this.fetchPatientTrend(payload)
        .then((res) => {
          this.chartData = res.data.chartData;
          const { labels, datasets } = res.data.chartData;
          if (this.selectedType !== "location") {
            const trends = datasets.map((e) => e.label);
            this.filteredData = labels.map((label, i) => {
              let data = { Category: label };
              let total = 0;
              trends.map((_, index) => {
                total += datasets[index].data[i];
                data = {
                  ...data,
                  [datasets[index].label]:
                    datasets[index].data[i].toLocaleString("en-US"),
                };
              });
              return {
                ...data,
                Total: total.toLocaleString("en-US"),
              };
            });
          } else {
            const selectedTab = this.tabsName[this.active];
            this.customHeaders = [`${selectedTab.tab}`, "# of Patients"];
            this.filteredData = labels.map((label, i) => {
              return {
                [`${selectedTab.tab}`]: label,
                "# of Patients": (+datasets[0].data[i]).toLocaleString("en-US"),
              };
            });
          }
          const qs = {
            filter: this.selectedTab,
            type: this.selectedType,
          };
          this.$router.replace({ query: qs });
          this.$vs.loading.close();
        })
        .catch((err) => {
          console.error("##ERR: ", err.message);
          this.$vs.loading.close();
        });
    },
    showBrand(value) {
      this.sideMenuOptions[value] = !this.sideMenuOptions[value];
      if (this.sideMenuOptions[value]) {
        Object.keys(this.sideMenuOptions).map((key) => {
          if (key !== value) {
            this.sideMenuOptions[key] = false;
          }
        });
      }
    },
    getTotal(index) {
      let dataIndex = this.chartData.labels.findIndex((e) => e === index);
      let totalAmount = this.chartData.datasets.reduce((total, curr) => {
        total += curr.data[dataIndex];
        return total;
      }, 0);
      return totalAmount;
    },
    getHeaderValue(value) {
      if (this.selectedTab === "day") {
        return moment(value, "DD-MM-YYYY").format("LL");
      }
      if (this.selectedTab === "month") {
        let splitData = value.split(" ");
        return `${moment()
          .month(parseInt(splitData[0]) - 1)
          .format("MMMM")} ${splitData[1]}`;
      }
      return value;
    },
  },
  created() {
    this.selected = _.cloneDeep(this.FILTERS_SELECTED);
    let userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if (userType && userType.userType === "superAdmin") {
      this.urlType = "SuperAdmin";
    } else if (
      userType &&
      userType.organizations[0].role.toUpperCase() === "supplier".toUpperCase()
    ) {
      this.urlType = "Supplier";
      this.userbrands = userType.brands;
    } else {
      this.urlType = "OrgOwner";
    }
    if (
      localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      )
    ) {
      this.orgId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
    }
    if (userType && userType.brands.length) {
      this.supplierCurrentBrands = userType.brands;
    } else {
      // Assign invalid brand id for account's unassigned brand
      this.supplierCurrentBrands = [0]
    }
    this.customHeaders = this.ageRows;
    this.tabsName = this.defaultTabs;
    if (userType && userType.brands.length) {
      this.selected.brand = userType.brands;
    }
    this.getPatientTrends();
  },
  destroyed() {
    this.hideMarkerWidget();
  },
  watch: {
    filteredData: {
      handler() {
        const randNumber = Math.random().toString(16).slice(2);
        this.tableKey = randNumber;
      },
      deep: true,
    },
    active: {
      handler(newVal) {
        this.selectedTab = this.tabsName[newVal].type;
        this.chartData = {};
        this.filteredData = [];
        this.getPatientTrends();
      },
    },
    sideMenuOptions: {
      handler(options) {
        const keys = Object.keys(options);
        for (let i = 0; i < keys.length; i++) {
          if (options[keys[i]]) this.selectedType = keys[i];
        }
      },
      deep: true,
    },
    selectedType: {
      handler(type) {
        this.isFetching = true;
        let customHeaders = this.ageRows;
        if (type === "gender") {
          customHeaders = this.genderRows;
        }
        this.customHeaders = customHeaders;

        this.active = 0;
        let selectedTab = this.defaultTabs[0].type;
        let tabs = this.defaultTabs;
        if (type === "location") {
          selectedTab = this.locationTabs[0].type;
          tabs = this.locationTabs;
        }
        this.$nextTick(() => {
          this.tabsName = tabs;
          this.isFetching = false;
        });
        this.selectedTab = selectedTab;
        this.filteredData = [];
        this.chartData = {};
        this.getPatientTrends();
      },
    },
    selected: {
      handler() {
        this.getPatientTrends();
      },
      deep: true,
    },
    isAllClinics: {
      handler() {
        this.getPatientTrends();
      },
    },
  },
};
</script>

<style>
  .insightsTabs .vs-tabs--ul {
    overflow: auto
  } 
  .insightsTabs .vs-tabs--li button.vs-tabs--btn {
    padding: .5rem 1rem;
  }
  .listHover:hover {
    background-color: rgba(111, 214, 198, 0.6);
    color: #3e695c;
    border-radius: 0.5em;
  }
  .categoryActive {
    background-color: #074230;
    color: white;
    border-radius: 0.5em;
  }
  .categoryActive span {
    color: white !important;
  }
  .customtable .vs-table--thead th {
      background: #f3faf8;
  }
  /* Responsive <table> */
  @media screen and (max-width: 600px) {
    .customtable .vs-table--content .vs-con-table .vs-con-tbody {
        border: none;
    }
    .customtable .vs-table--tbody-table {
        min-width: unset;
    }
    .customtable .vs-table--header {
      flex-direction: column;
    }
    .customtable .vs-table--thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      display: none !important;
    }
    .customtable .vs-table--tr {
      display: block;
      margin-bottom: .625em;
    }
    .customtable .vs-table--td {
      border-bottom: .05rem solid #ddd;
      display: block;
      font-size: 1rem;
      text-align: right;
    }
    .customtable .vs-table--td:first-child{
      min-width: 230px;
    }
    .customtable .vs-table--td::before {
      content: attr(data-label);
      float: left;
      font-weight: 600;
    }
    .customtable .vs-table--td:last-child {
      border-bottom: 0;
    }
  }
</style>