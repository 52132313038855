<template>
  <div>
    <vs-row vs-w="12">
      <GlobalFilters
        :categories="categories"
        :subCategories="subCategories"
        :selectedCategory="selectedCategory"
        :selectedSubCategory="selectedSubCategory"
        @updateSelectedCategory="selectedCategory = $event"
        @updateSelectedSubCategory="selectedSubCategory = $event"
        :selected="selected"
        :page="'patients'"
        @updateFilters="updateFilters"
        :urlType="urlType"
      />
      <!-- Chart -->
      <!-- <div class="w-full m-5" v-if="urlType === 'Supplier'">
        <label for>All Clinics</label>
        <vs-switch
          class="m-2"
          v-model="isAllClinics"
        />
      </div> -->
      <vs-col vs-w="12">
        <BarChart :chartData="chartData" :chartOptions="chartOptions" />
      </vs-col>
      <!-- <vs-col vs-w="12" class="mt-4">
        <div class="flex items-center quick__insights p-2">
          <feather-icon
            style="cursor: pointer"
            icon="InfoIcon"
            svgClasses="h-4 w-4"
            class="ml-1"
          />
          <span class="ml-3 text-sm">QUICK INSIGHT: <b>9.8 months</b> your average time for returning customers (<b>+2.7 months</b> compared to other Fresh Clinics)</span>
        </div>
      </vs-col> -->
    </vs-row>
  </div>
</template>

<script>
import BarChart from "../charts/BarChart.vue";
import GlobalFilters from "../common/GlobalFilters";
import { filterCategories } from "../common/GlobalFilters/filterConstant.js";

export default {
  components: {
    BarChart,
    GlobalFilters,
  },
  props: {
    type: {
      type: String,
      default: "month",
    },
    chartDataProps: {
      type: Object,
    },
    selected: {
      type: Object,
    },
    isAllClinicsProps: {
      type: Boolean,
    },
  },
  data() {
    return {
      categories: [
        filterCategories.ORGANIZATIONS,
        filterCategories.CLINICS,
        filterCategories.PATIENTS,
      ],
      selectedCategory: {},
      subCategories: [],
      selectedSubCategory: {},
      isAllClinics: false,
      chartData: {},
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              boxWidth: 12,
            },
          },
          title: {
            display: true,
            text: "",
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: "",
            },
            min: 0,
          },
          x: {
            title: {
              display: true,
              text: "",
            },
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 10,
              },
              maxRotation: 90,
              minRotation: 90,
            },
          },
        },
      },
      urlType: "",
    };
  },
  methods: {
    updateFilters(newVal) {
      this.$emit("updateFilters", newVal);
    },
  },
  watch: {
    chartDataProps: {
      handler(newVal) {
        this.chartData = newVal;
      },
      immediate: true,
    },
    chartData: {
      handler(newVal) {
        this.$emit("updateChartData", newVal);
      },
      deep: true,
    },
    type: {
      handler(newVal) {
        this.chartOptions.scales.x.title.text = newVal.toUpperCase();
      },
      immediate: true,
    },
    isAllClinicsProps: {
      handler(newVal) {
        this.isAllClinics = newVal;
      },
      immediate: true,
    },
    isAllClinics: {
      handler(newVal) {
        this.$emit("updateIsAllClinics", newVal);
      },
    },
  },
  created() {
    let userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if (userType && userType.userType === "superAdmin") {
      this.urlType = "SuperAdmin";
    } else if (
      userType &&
      userType.organizations[0].role.toUpperCase() === "supplier".toUpperCase()
    ) {
      this.urlType = "Supplier";
    } else {
      this.urlType = "OrgOwner";
    }
    if (
      localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      )
    ) {
      this.orgId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
    }
    if (userType && userType.brands.length) {
      this.supplierCurrentBrands = userType.brands;
    }
  },
};
</script>

<style scoped>
.quick__insights {
  background: #d8effe;
  border: 1.07993px solid #c3e2f7;
  border-radius: 7px;
  color: #0098f8;
}
</style>